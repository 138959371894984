import React from 'react';
import { NavLink } from 'react-router-dom';

import './Navigation.css';

function Navigation({ items }) {
  function Link({ title, to }) {
    return (
      <NavLink to={`/${to}`} activeClassName="active" exact>
        <strong>{title}</strong>
      </NavLink>
    );
  }

  return (
    <div className="block col-12 wrapper">
      <div className="navigation">
        {items.map((item, i) => (
          <Link key={i} title={item.title} to={item.view} />
        ))}
      </div>
    </div>
  );
}

export default Navigation;
