export const signatureExample = `
const crypto = require('crypto');
const qs = require('querystring');
const axios = require('axios');

const BASE_URL = 'https://app.xcalibra.com';
const API_KEY = process.env.API_KEY; // Something like: BOT_j4boSycP4NnVUSxRDZapYBzvnpzdPg9NxExpTRpnHU8bsrsSYE
const SECRET = process.env.SECRET; // Something like: SIG_99ibKNjiKobMAyfhq8dA1LYyRtpCWL9e25HSp4c2yB11TBcY7r

let lastNonce = 0;

function makeRequest(verb, endpoint, query = undefined, body = undefined) {
  // Add query string to url path
  let urlPath = endpoint;
  if (query) {
    urlPath += '?' + qs.stringify(query);
  }
  
  // We must generate request data ourselves, so we can include it in signature
  let data;
  if (body) {
    data = JSON.stringify(body);
  }
  
  // Generate the nonce
  const nonce = Math.max(lastNonce + 1, new Date().valueOf());
  lastNonce = nonce;
  
  // Generate the signature
  const hmac = crypto.createHmac('sha256', SECRET);
  hmac.update(verb.toLowerCase());
  hmac.update(urlPath);
  hmac.update(String(nonce));
  if (data) {
    hmac.update(data);
  }
  const signature = hmac.digest('hex');
  
  // Prepare axios options
  const axiosReq = {
    method: verb,
    url: BASE_URL + urlPath,
    data,
    headers: {
      Authorization: 'Bearer ' + API_KEY,
      'Content-Type': 'application/json',
      'XC-Signature': signature,
      'XC-Nonce': nonce,
    },
  };
  
  // Make the request and return response data. NOTE: Error handling is excluded for brevity's sake
  return axios(axiosReq).then(res => {
    return res.data;
  });
}
`.trim();

export const marketDepthExample = `
const depth = await makeRequest('GET', '/api/private/v1/market-depth/SFX_BTC');

const topLevel = depth.sell_levels[0];
if (!topLevel) {
  // Nothing to do
  return;
}

// Position slightly below market level
const targetPrice = topLevel.price - 0.001;

// Place a buy order
const report = await makeRequest('POST', '/api/private/v1/orders', null, {
  pair: 'SFX_BTC',
  type: 'limit',
  side: 'buy',
  price: targetPrice,
  quantity: '100'
});
console.log(report);
`.trim();

export const cancelAllOrdersExample = `
const myOrders = await makeRequest('GET', '/api/private/v1/orders/for-pair/SFX_BTC');

for (const order of myOrders.buy_list) {
  await makeRequest('DELETE', '/api/private/v1/orders/' + order.id);
}
`.trim();
