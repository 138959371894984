import React from 'react';
import { Helmet } from 'react-helmet';
import PropTypes from 'prop-types';

const PageSeo = ({ title, description }) => (
  <Helmet>
    <title>{title}</title>
    <meta name="description" content={description}></meta>
  </Helmet>
);

PageSeo.propTypes = {
  title: PropTypes.string.isRequired,
  description: PropTypes.string.isRequired,
};
export default PageSeo;
