import React from 'react';

import Swagger from '../components/Swagger';
import Section from '../components/Section';

import './Cmc.css';

function Cmc({ spec }) {
  return (
    <div className="wrapper public-api">
      <Section>
        <p>
          The CMC API allows read access to public market data.
          <br /> <br />
          No authentication is necessary but you must not excessively use any API endpoint.
        </p>
      </Section>
      <Section title="Endpoints">
        <Swagger spec={spec} interactive />
      </Section>
    </div>
  );
}

export default Cmc;
