import React from 'react';
import PropTypes from 'prop-types';
import SwaggerUi, { presets } from 'swagger-ui';

import 'swagger-ui/dist/swagger-ui.css';
import './Swagger.css';

export default class Swagger extends React.Component {
  static propTypes = {
    spec: PropTypes.object,
    interactive: PropTypes.bool,
  };

  componentDidMount() {
    this._swagger = SwaggerUi({
      dom_id: '#swagger_container',
      spec: this.props.spec,
      presets: [presets.apis],
      displayOperationId: true,
      displayRequestDuration: true,
      defaultModelsExpandDepth: 0,
    });
  }

  render() {
    const className = [
      'Swagger',
      !this.props.interactive && 'Swagger-non-interactive',
      'Swagger-hide-tags',
    ]
      .filter(Boolean)
      .join(' ');
    return <div className={className} id="swagger_container" />;
  }
}
