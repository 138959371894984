import React from 'react';
import { Link } from 'react-router-dom';

import Section from '../components/Section';
import CodeBlock from '../components/CodeBlock';

import './Overview.css';
import PageSeo from '../components/PageSeo';

const Deprecated = ({ children }) => {
  return <span className="Overview-deprecated">{children}</span>;
};

function Overview() {
  return (
    <div className="wrapper Overview">
      <PageSeo
        title="Crypto exchange API documentation - Xcalibra"
        description="Get a fully featured REST API for interacting with the exchange. Xcalibra provides both a private API to access user data, and a public API for public market data."
      />
      <Section>
        <p>
          Xcalibra provides a fully featured REST API for interacting with the exchange. All market
          and user data is available.
          <br /> Use private API to access user data and public API for public market data.
          <br />
          <br />
        </p>
      </Section>

      <Section title="API Changelog">
        <Section title="Dec 22, 2020" sub>
          <ul>
            <li>
              We made slight additions to our public api, to allow for CCXT integration.
              <code>{'/api/public/v1/pairs/{pair}'}</code> endpoint now includes{' '}
              <code>base_decimals</code> and <code>quote_decimals</code>.
            </li>
          </ul>
        </Section>
        <Section title="Sep 15, 2020" sub>
          <ul>
            <li>
              We have <Deprecated>deprecated</Deprecated> the use of <code>instrument</code> query
              param in <code>/api/private/v1/deposits</code> and{' '}
              <code>/api/private/v1/withdrawals</code>. You should instead use "
              <code>instruments</code>", which allow you to specify a comma-separated list of
              instruments to filter for (example: "<code>SFX,SFT</code>"). The old query param will
              continue to work for some time.
            </li>
          </ul>
        </Section>
        <Section title="May 1, 2020" sub>
          <ul>
            <li>
              We have updated our API spec to have a bit better definitions of different data types,
              especially <code>sort_field</code>-s in criterias.
            </li>
            <li>
              <p>
                When fetching Deposits through <em>private API</em>, there is now the{' '}
                <code>completed_at</code> field. This field will almost always be the same as{' '}
                <code>finalized_at</code>, except in cases when there was an issue in closing the
                deposit (eg. the system was in a maintenance mode). From now on, you should check{' '}
                <code>completed_at</code> to determine if deposit has been completed, and{' '}
                <code>failure_code</code> to see if it has failed or succeeded.
              </p>
              <p>
                Correspondingly, there is now a <code>completed</code> filter when searching
                deposits.
              </p>
            </li>
          </ul>
        </Section>
        <Section title="April 24, 2020" sub>
          <ul>
            <li>
              <p>
                We have cleaned up some old deprecated methods and features on the{' '}
                <strong>private API</strong>:
              </p>
              <ul>
                <li>
                  <p>
                    We have removed the legacy behavior of the <code>{'/orders/{pair}'}</code>{' '}
                    endpoint. Only the <code>{'/orders/for-pair/{pair}'}</code> endpoint is
                    supported from now on.
                  </p>
                </li>
                <li>
                  <p>
                    In the <code>{'/deposits'}</code> endpoint, we have removed the deprecated{' '}
                    <code>only_incoming</code> and <code>only_finalized</code> parameters.
                  </p>
                </li>
              </ul>
            </li>
            <li>
              We have fixed the "<code>500: Internal Error</code>" failure on the{' '}
              <strong>public API</strong> endpoint <code>{'/pairs/:pair'}</code>
            </li>
          </ul>
        </Section>
        <Section title="March 19, 2020" sub>
          <p>
            We have improved security of our <em>private API</em> by adding support for request{' '}
            <em>
              <strong>nonces</strong>
            </em>
            .
          </p>
          <p>
            Nonce is a distinct monotonically increasing number that you must include with every
            request. Server will reject any request with duplicate nonce, as well as requests with a
            nonce that is lower than previous received nonces for that API key (barring a brief
            grace period). The purpose of this enhancement is to prevent{' '}
            <a href="https://en.wikipedia.org/wiki/Replay_attack" target="_blank">
              replay attacks
            </a>
            .
          </p>
          <p>
            We also took this opportunity to prefix our custom header keys with "<code>xc-</code>",
            for debugging and clarity purposes.
          </p>
          <p>
            In order to transition your bot to the new system, you need to make the following
            changes:
          </p>
          <ul>
            <li>
              <p>
                Generate a nonce for every request. A good way to do this is to just take the
                current unix time or epoch millisecond.
              </p>
              <p>Javascript example:</p>
              <CodeBlock>new Date().valueOf()</CodeBlock>
            </li>
            <li>
              Add the nonce as the "<code>xc-nonce</code>" header.
            </li>
            <li>
              <p>
                When generating signature, add nonce as the 3rd parameter, after url path and before
                the body.
              </p>
              <p>Before:</p>
              <CodeBlock>HMAC('sha256', signature_secret, verb + url_path + body)</CodeBlock>
              <p>Now:</p>
              <CodeBlock>
                HMAC('sha256', signature_secret, verb + url_path + nonce + body)
              </CodeBlock>
            </li>
            <li>
              Write signature into the "<code>xc-signature</code>" header, instead of "
              <code>signature</code>".
            </li>
          </ul>
          <p>
            Please check out the <Link to="/private">private API page</Link> for more details and
            code examples.
          </p>
          <p>
            The old request format, with the unprefixed <code>signature</code> headear and without
            the nonce will continue to work for now, but should be considered{' '}
            <Deprecated>deprecated</Deprecated>. We will disable the nonce-less requests in one of
            the upcoming updates, so please upgrade your bots to the new system ASAP.
          </p>
        </Section>
        <Section title="January 20, 2020" sub>
          <ul>
            <li>
              We have fixed and updated javascript demo code. It is no longer using deprecated
              endpoints
            </li>
            <li>
              We have fixed a problem when calling urls terminated with "<strong>?</strong>"{' '}
              (example: <code>{`/api/something?`}</code>) would cause API to return "Invalid
              signature" error.
            </li>
            <li>
              We have fixed a problem when using a deprecated endpoint{' '}
              <code>{`/api/private/v1/orders/{pair}`}</code>
            </li>
            <li>
              We have fixed broken endpoint <code>{`/api/private/v1/deposits`}</code>
            </li>
            <li>
              We have added <code>sort_index</code> to <code>PublicTradeInfo</code> objects. You can
              use this value to sort trades derived from the same order based on their matching
              sequence.
            </li>
            <li>
              We have fixed the problem where <code>/api/public/v1/status</code> endpoint returned
              incorrect app name and version.
            </li>
          </ul>
        </Section>
        <Section title="October 25, 2019" sub>
          <ul>
            <li>
              In <code>InstrumentPolicy</code>, we have renamed <code>disabled_deposits</code> to{' '}
              <code>disable_deposits</code> and <code>disabled_withdrawals</code> to{' '}
              <code>disable_withdrawals</code>. Old properties are still available, but{' '}
              <Deprecated>deprecated</Deprecated>.
            </li>
            <li>
              <code>InstrumentPolicy</code> now also includes reason why deposits or withdrawals are
              disabled, as it would appear in the UI.
            </li>
            <li>
              We have added <code>/api/public/v1/pairs</code> and{' '}
              <code>/api/public/v1/pairs/:pair</code> endpoints. They return <code>PairPolicy</code>{' '}
              objects, which provide info about status and policies of xcalibra markets.
            </li>
          </ul>
        </Section>
        <Section title="October 10, 2019" sub>
          <ul>
            <li>
              <p>
                In <code>{'/deposits'}</code> endpoint, we have <Deprecated>deprecated</Deprecated>{' '}
                separate <code>only_incoming</code> and <code>only_finalized</code> params. Instead,
                you can use the unified <code>finalized=true</code> or <code>finalized=false</code>{' '}
                param.
              </p>
            </li>
          </ul>
        </Section>
        <Section title="August 20, 2019" sub>
          <ul>
            <li>
              <p>
                We have added a private API endpoint <code>{'/trades/for-order/{orderId}'}</code>,
                which you can use to fetch all the trades that involve a given order id.
              </p>
              <p>
                If <code>{'/orders/{orderId}'}</code> endpoint returns 404, and you haven't
                cancelled your order, you can use this endpoint to get the details of how the order
                was filled.
              </p>
            </li>
          </ul>
        </Section>
        <Section title="August 16, 2019" sub>
          Private API has been updated with several new endpoints.
          <ul>
            <li>
              <p>
                We have added an endpoint for fetching a single order{' '}
                <code>{'/orders/{orderId}'}</code>.
              </p>{' '}
              <p>
                Unfortunately, in order to get this working, we had to{' '}
                <Deprecated>deprecate</Deprecated> <code>{'/orders/{pair}'}</code> endpoint, which
                has been moved to <code>{'/orders/for-pair/{pair}'}</code>. If API detects you are
                still using the old endpoint (trying to get orders for instrument), it will redirect
                you to the new one, with appropriate deprecation header. Please update your clients.
              </p>
            </li>
            <li>
              <p>
                We have added endpoints for retrieving trade history (
                <code>{'/trades/history'}</code>) and recent trades (<code>{'/trades/recent'}</code>
                ).{' '}
              </p>
              <p>
                Trade history allows you more control over the query (pagination, sorting), but
                doesn't include the most recent trades (1-5 minutes). The recent trades endpoint
                allows you to get trades as soon as they happen, but you are limited to up to latest
                1000.
              </p>
              <p>
                We will be working on merging these two endponts together and giving you the best of
                both worlds. For now, please bear with us.
              </p>
            </li>
          </ul>
        </Section>
        <Section title="July 24, 2019" sub>
          <ul>
            <li>
              If time range is not specified, price history now returns the latest data, instead of
              the oldest records.
            </li>
            <li>Added better examples for all requests</li>
          </ul>
        </Section>
        <Section title="July 4, 2019" sub>
          <ul>
            <li>Public API published</li>
          </ul>
        </Section>
        <Section title="May 15, 2019" sub>
          <ul>
            <li>Private API published</li>
          </ul>
        </Section>
      </Section>
    </div>
  );
}

export default Overview;
