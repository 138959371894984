import React from 'react';

import './Section.css';

const Section = ({ title, children, sub, hidden }) => {
  return (
    <section className={`Section wrapper ${hidden ? 'Section-hidden' : ''}`}>
      <div className="block col-12">
        {sub ? <h3>{title}</h3> : <h2>{title}</h2>}
        {children}
      </div>
    </section>
  );
};

export default Section;
