import React from 'react';
import { Link } from 'react-router-dom';
import logo from '../media/logo.png';

const Header = ({ version }) => {
  return (
    <section className="Header wrapper">
      <div className="block col-12 info">
        <Link to="/" style={{ textDecoration: 'none' }}>
          <img src={logo} alt="Logo image" />
        </Link>
        <small className="version" style={{ whiteSpace: 'nowrap' }}>
          Version: {version}
        </small>
      </div>
    </section>
  );
};

export default Header;
