import React from 'react';
import ReactDOM from 'react-dom';

import './index.css';

import App from './components/App';
import privateSpec from './specs/private.json';
import publicSpec from './specs/public.json';
import cmcSpec from './specs/cmc.json';

const backendUrl = process.env.REACT_APP_BACKEND_URL;
if (backendUrl) {
  privateSpec.servers.push({
    url: backendUrl,
  });

  publicSpec.servers.push({
    url: backendUrl,
  });

  cmcSpec.servers.push({ url: backendUrl });
}

ReactDOM.render(
  <App specs={{ private: privateSpec, public: publicSpec, cmc: cmcSpec }} />,
  document.getElementById('app')
);
