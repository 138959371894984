import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { BrowserRouter as Router, Route, Switch } from 'react-router-dom';

import Header from './Header';
import Public from '../pages/Public';
import Private from '../pages/Private';
import Cmc from '../pages/Cmc';
import Overview from '../pages/Overview';
import Navigation from './Navigation';

import './App.css';

const VIEW = {
  overview: 'overview',
  private: 'private',
  public: 'public',
  clients: 'clients',
};

const NAV_ITEMS = [
  { title: 'Overview', view: '' },
  { title: 'Private API', view: VIEW.private },
  { title: 'Public API', view: VIEW.public },
];

class App extends Component {
  static propTypes = {
    specs: PropTypes.object,
  };

  constructor(props) {
    super(props);
    this.state = {
      view: VIEW.overview,
    };
  }

  changeView = view => this.setState({ view });

  renderLink(title, view) {
    return (
      <a
        href={`#${view}`}
        className={`${this.state.view === view ? 'active' : ''}`}
        onClick={() => this.changeView(view)}
      >
        <strong>{title}</strong>
      </a>
    );
  }

  render() {
    // NOTE: We add swagger-ui here, so that swagger's CSS can be used all over the place
    return (
      <Router>
        <div className="App swagger-ui">
          <Header version={this.props.specs.private.info.version} />
          <Navigation items={NAV_ITEMS} active={this.state.view} onChange={this.changeView} />

          <Switch>
            <Route path="/" exact component={Overview} />
            <Route path="/public" component={() => <Public spec={this.props.specs.public} />} />
            <Route path="/private" component={() => <Private spec={this.props.specs.private} />} />
            <Route path="/cmc" component={() => <Cmc spec={this.props.specs.cmc} />} />
          </Switch>
        </div>
      </Router>
    );
  }
}

export default App;
