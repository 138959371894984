import React from 'react';

import Swagger from '../components/Swagger';
import Section from '../components/Section';

import './Public.css';
import PageSeo from '../components/PageSeo';

function Public({ spec }) {
  return (
    <div className="wrapper public-api">
      <PageSeo
        title="Public API for crypto market data - Xcalibra"
        description="Get access to public crypto market data from Xcalibra trading platform. Tickets, instruments, status, order book, crypto pairs, and more. No authentication required."
      />
      <Section>
        <p>
          The public API allows read access to public market data.
          <br /> <br />
          No authentication is necessary but you must not excessively use any API endpoint.
        </p>
      </Section>
      <Section title="Endpoints">
        <Swagger spec={spec} interactive />
      </Section>
    </div>
  );
}

export default Public;
