import React from 'react';

import Swagger from '../components/Swagger';
import Section from '../components/Section';
import CodeBlock from '../components/CodeBlock';
import * as examples from '../lib/examples';
import PageSeo from '../components/PageSeo';

function Private({ spec }) {
  return (
    <div className="wrapper private-api">
      <PageSeo
        title="Private API for crypto trading bots - Xcalibra"
        description="Automate your trades with Xcalibra private API. Your trading bots will have the same privileges and limits as when you are trading in person."
      />
      <section className="Instructions">
        <Section title="">
          <p>
            Xcalibra offers a private web API that is suitable for trading automation by our users.
          </p>
          <p>
            In order to access the API, you must have a fully activated account on{' '}
            <a href="https://trade.xcalibra.com/" target="_blank" rel="noopener noreferrer">
              the platform
            </a>
            . Your bots will have the same privileges and limits as when you are trading in person.
            So you still have to finish all the KYC and AML verification steps you normally would.
          </p>
        </Section>

        <Section title="Getting started">
          <Section sub title="Sign Up">
            <p>If you do not have Xcalibra account yet, use the button below to sign up.</p>
            <a href="https://trade.xcalibra.com/register" target="_blank">
              <button className="btn execute">Sign Up</button>
            </a>
          </Section>

          <Section sub title="Verify">
            <p>
              To unlock full functionallity of the exchange you must complete KYC (Know Your
              Customer) level 2 verification process.
              <br />
              To get started, go to{' '}
              <a href="https://trade.xcalibra.com/settings/kyc" target="_blank">
                KYC settings page
              </a>{' '}
              and fill the form.
            </p>
          </Section>

          <Section sub title="API Key">
            <p>
              Go to your{' '}
              <a href="https://trade.xcalibra.com/settings/api" target="_blank">
                API settings page
              </a>
              , and create a new api key. Take note of the following fields:
            </p>
            <ul>
              <li className="mb2">
                <strong>API key:</strong>
                <br />
                This is the key that will identify your bot with the platform.{' '}
                <strong>
                  <em>Keep this information secret.</em>
                </strong>
              </li>
              <li className="mb2">
                <strong>Secret key:</strong>
                <br />
                The secret that will be used to sign your requests and ensure they are not tempered
                en-route.{' '}
                <strong>
                  <em>Keep this information secret.</em>
                </strong>
              </li>
              <li className="mb2">
                <strong>Status:</strong>
                <br />
                Flag that indicates whether your key is enabled. If you suspect your secret
                information was leaked, you can click here to disable it.
              </li>
              <li className="mb2">
                <strong>IP access restriction:</strong>
                <br />
                For additional security, you can write the IP address of the server where your bots
                will be running. Only requests from that address will be able to trade using this
                key.{' '}
                <a
                  href="https://en.wikipedia.org/wiki/Classless_Inter-Domain_Routing"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  CIDR notation
                </a>{' '}
                is also supported.
              </li>
              <li className="mb2">
                <strong>Permissions:</strong>
                <br />
                List of actions your bot will be able to perform using this key.
                <strong>
                  <em>
                    You need to enable at least one permission for your bot to be able to do
                    anything
                  </em>
                </strong>
              </li>
            </ul>
            <p>
              Remember to keep your API key and signature secret secured on your server. If someone
              were to get ahold of them, they would be able to trade and request withdrawals in your
              name.
            </p>
          </Section>
        </Section>

        <Section title="Request format">
          <p>
            The base URL your bots should target is:{' '}
            <strong>
              <code>{(spec.servers[0] || {}).url || null}</code>
            </strong>
          </p>
          <p>Each request you make must contain the following headers:</p>
          <ul>
            <li>
              <code>
                <strong>Content-type</strong>: application/json
              </code>
            </li>
            <li>
              <code>
                <strong>Authorization</strong>: Bearer <em>$your_api_key</em>
              </code>
            </li>
            <li>
              <code>
                <strong>XC-Signature</strong>: <em>$generated_signature</em>
              </code>
            </li>
            <li>
              <code>
                <strong>XC-Nonce</strong>: <em>$generated_nonce</em>
              </code>
            </li>
          </ul>
          <p>
            Nonce has to be a monotonically increasing integer. Eg. 1, 2, 3, 1000, 2000... Server
            will reject any request with nonce that is equal or lower than any previous nonce that
            was seen for a particular api key. If you want to use multiple apps at the same time, we
            recommend that you generate a separate bot account for each. A good way to generate a
            nonce is to take a unix timestamp (milliseconds since epoch), and increase that number
            for each request you send.
          </p>
          <p>Request signature is generated based on the following formula:</p>
          <CodeBlock>HMAC('sha256', signature_secret, verb + url_path + nonce + body)</CodeBlock>
          <p>Where:</p>
          <ul>
            <li>
              <code>
                <strong>signature_secret</strong>
              </code>{' '}
              is the bot's secret, taken from the xcalibra interface
            </li>
            <li>
              <code>
                <strong>verb</strong>
              </code>{' '}
              is lower-case web verb of your request. One of: get, put, post, delete and patch.
            </li>
            <li>
              <code>
                <strong>url_path</strong>
              </code>{' '}
              is the full path of your request, including the query string. For example:{' '}
              <code>"/api/v1/path/to/something?key=value"</code>
            </li>
            <li>
              <code>
                <strong>nonce</strong>
              </code>{' '}
              is the unique number you have generated for this request and provided in the{' '}
              <code>xc-nonce</code> header
            </li>
            <li>
              <code>
                <strong>body</strong>
              </code>{' '}
              is the raw body of the request you are making (nothing should be added for body-less
              requests)
            </li>
          </ul>
        </Section>

        <Section title="Examples">
          <p>
            The examples will be given in node.js, but they should be easy to adjust for other
            languages
          </p>
          <p>
            We suggest you start by creating a function that can generate proper signatures and make
            requests for you. Here's an example using{' '}
            <a href="https://www.npmjs.com/package/axios" target="_blank" rel="noopener noreferrer">
              axios
            </a>{' '}
            library:
          </p>
          <CodeBlock>{examples.signatureExample}</CodeBlock>
          <p>
            Now, you can use this function to interrogate the API. Here's how you can get current
            market depth and place a limit order based on its state:
          </p>
          <CodeBlock>{examples.marketDepthExample}</CodeBlock>
          <p>And here's how you can cancel all your BUY SFX_BTC orders:</p>
          <CodeBlock>{examples.cancelAllOrdersExample}</CodeBlock>
        </Section>
      </section>
      <Section title="Endpoints">
        <Swagger spec={spec} />
      </Section>
    </div>
  );
}

export default Private;
