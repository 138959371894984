import React from 'react';
import Highlight from 'react-highlight';

const CodeBlock = ({ language = 'javascript', children }) => {
  return (
    <Highlight className={language} language={language}>
      {children}
    </Highlight>
  );
};

export default CodeBlock;
